<template>
     <v-autocomplete
        v-model="model"
        :rules="rules"
        :items="searchResults"
        :loading="searchLoading"
        :search-input.sync="searchSync"
        :label="label"
        item-text="Value"
        placeholder="Start typing an 34, lastname, or group name"
        :disabled="disabled"
        clearable
        :filter="filterObject"
        return-object
    >
    <template slot="item" slot-scope="data">
      <template v-if="data.item && data.item.Key">
        {{ data.item.Key }} - {{ data.item.Value }}
      </template>
      <template v-else>
        {{ data.item }}
      </template>
    </template>
    </v-autocomplete>
</template>

<script>
import axios from 'axios'

export default {
  name: 'user-group-search-component',
  props: ['value', 'disabled', 'rules', 'label', 'noClear'],
  watch: {
    searchSync: {
      handler () {
        if (this.searchSync) {
          if (this.model && this.searchSync === this.model) {
            return
          }
          clearTimeout(this.searchTimeout)

          // Make a new timeout set to go off in 800ms
          this.searchTimeout = setTimeout(() => {
            this.searchForUserAndGroup()
          }, 500)
        } else {
          this.searchResults = [ ]
          this.model = ''
        }
      }
    },
    model: {
      handler () {
        this.$nextTick(() => {
          if (!this.noClear) {
            this.model = ''
          }
        })
      }
    }
  },
  computed: {
    model: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  data () {
    return {
      searchResults: [],
      searchLoading: false,
      searchTimeout: null,
      searchSync: null
    }
  },
  methods: {
    filterObject (item, queryText, itemText) {
      if (item.Key) {
        return (item.Key.toLowerCase().includes(queryText.toLowerCase()) || item.Value.toLowerCase().includes(queryText.toLowerCase()))
      } else {
        return true
      }
    },
    searchForUserAndGroup: function () {
      this.searchLoading = true
      this.searchResults = []
      this.searchForUser().then(() => {
        this.searchForGroup().then(() => {
          this.searchLoading = false
        })
      })
    },
    async searchForUser () {
      this.searchLoading = true
      return axios(`${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/search/user?searchText=${this.searchSync}`, { method: 'GET', params: { } })
        .then((response) => {
          if (response.data) {
            this.searchResults = this.searchResults.concat(response.data)
          }
        }, () => {
        })
    },
    async searchForGroup () {
      this.searchLoading = true
      return axios(`${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/search/group?searchText=${this.searchSync}`, { method: 'GET', params: { } })
        .then((response) => {
          if (response.data) {
            this.searchResults = this.searchResults.concat(response.data)
          }
        }, () => {
        })
    }
  },
  beforeMount () {
    if (this.value) {
      this.searchResults = [this.value]
    }
  }
}
</script>

<style>
</style>
