<template>
  <v-container>
      <v-row>
        <v-col cols="12" md="12" id="v-step-0">
            <h1>{{ repository.displayName }}</h1>
            <v-skeleton-loader
              v-if="!repository.displayName"
              class="mx-auto"
              min-width="500"
              type="heading"
            ></v-skeleton-loader>
        </v-col>
      </v-row>
    <v-divider/>
    <v-row>
        <v-col>
            <h2>Edit Repository Details:</h2>
        </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card
          :loading="isSubmitted"
          class="ap-2"
        >
          <template v-slot:progress>
            <v-progress-linear
              absolute
              color="green lighten-3"
              height="4"
              indeterminate>
            </v-progress-linear>
          </template>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field label="Name" disabled v-model="repository.displayName" readonly />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field label="Repository Url for private package usage and deployments" disabled v-model="repository.repositoryUrl" readonly>
                    <v-btn class="lock-button" slot="append" v-clipboard:copy="repository.repositoryUrl" v-clipboard:success="copyMouseClick" icon>
                      <v-icon>mdi-clipboard-text-play-outline</v-icon>
                    </v-btn>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="repository.groupedRepositoryUrl">
                <v-col>
                  <v-text-field label="Repository Url with public and private packages" disabled v-model="repository.groupedRepositoryUrl" readonly>
                    <v-btn class="lock-button" slot="append" v-clipboard:copy="repository.groupedRepositoryUrl" v-clipboard:success="copyMouseClick" icon>
                      <v-icon>mdi-clipboard-text-play-outline</v-icon>
                    </v-btn>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    readonly
                    disabled
                    v-model="repository.repositoryType"
                    :items="repositoryType"
                    item-text="key"
                    item-value="value"
                    label="Repository Type"
                  ></v-select>
                </v-col>
              </v-row>
               <v-row>
                <v-col>
                  <v-text-field label="Username" disabled v-model="repository.username" readonly>
                    <v-btn class="lock-button" slot="append" v-clipboard:copy="repository.username" v-clipboard:success="copyMouseClick" icon>
                      <v-icon>mdi-clipboard-text-play-outline</v-icon>
                    </v-btn>
                  </v-text-field>
                </v-col>
              </v-row>
               <v-row>
                <v-col>
                  <v-text-field
                    :type="showRepoPassword ? 'text' : 'password'"
                    label="Password"
                    v-model="repository.password"
                    disabled
                  >
                    <v-btn icon class="lock-button" slot="prepend" @click.stop="showRepoPassword = !showRepoPassword">
                      <v-icon>{{ showRepoPassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
                    </v-btn>
                    <v-btn class="lock-button" slot="append" v-clipboard:copy="repository.password" v-clipboard:success="copyMouseClick" icon>
                      <v-icon>mdi-clipboard-text-play-outline</v-icon>
                    </v-btn>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-btn class="ma-2" :loading="isDeletingRepository" :disabled="isDeletingRepository" @click="displayDeleteDialog" color="error">Delete Repository</v-btn>
                  <v-btn class="ma-2" :loading="isResettingPassword" :disabled="isResettingPassword" @click="displayResetPasswordDialog" color="info">Reset Password</v-btn>
                </v-col>
              </v-row>
            </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
        <v-col>
            <h2>Repository Artifacts Available To Promote:</h2>
        </v-col>
    </v-row>
    <v-row v-if="!artifacts || artifacts.length == 0">
      <v-col>
        <p>No artifacts were found for this repository</p>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col v-for="(item, key) in artifactDictionary" :key="key" cols="12" md="6">
      <v-card
          max-width="500"
          min-width="344"
          min-height="130"
          class="mx-auto ma-2"
      >
        <v-list-item>
          <v-list-item-avatar>
            <v-img contain v-if="repository.repositoryType == 0" src="@/assets/ArtifactTypes/nugetIcon.png"></v-img>
            <v-img contain v-if="repository.repositoryType == 1" src="@/assets/ArtifactTypes/npm.png"></v-img>
            <v-img contain v-if="repository.repositoryType == 2" src="@/assets/ArtifactTypes/maven.png"></v-img>
            <v-img contain v-if="repository.repositoryType == 3" src="@/assets/ArtifactTypes/docker.png"></v-img>
            <v-img contain v-if="repository.repositoryType == 4" src="@/assets/ArtifactTypes/pypi.png"></v-img>
            <v-img contain v-if="repository.repositoryType == 5" src="@/assets/ArtifactTypes/bower.png"></v-img>
            <v-img contain v-if="repository.repositoryType == 6" src="@/assets/ArtifactTypes/helm.png"></v-img>
            <v-img contain v-if="repository.repositoryType == 7" src="@/assets/ArtifactTypes/raw.png"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="headline">{{ key }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.length }} Version(s) Available</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon @click="promoteArtifact(item, key)">
            <v-icon>mdi-briefcase-upload</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-col>
    </v-row>
    <v-row>
        <v-col>
            <h2>Promoted Artifacts</h2>
        </v-col>
    </v-row>
    <v-row v-if="!promotedArtifacts || promotedArtifacts.length == 0">
      <v-col>
        <p>No promoted artifacts were found for this repository</p>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col v-for="(item, key) in promotedArtifactsDictionary" :key="key" cols="12" md="6">
      <v-card
          max-width="500"
          min-width="344"
          min-height="130"
        class="mx-auto ma-2"
      >
        <v-list-item>
          <v-list-item-avatar>
            <v-img contain v-if="repository.repositoryType == 0" src="@/assets/ArtifactTypes/nugetIcon.png"></v-img>
            <v-img contain v-if="repository.repositoryType == 1" src="@/assets/ArtifactTypes/npm.png"></v-img>
            <v-img contain v-if="repository.repositoryType == 2" src="@/assets/ArtifactTypes/maven.png"></v-img>
            <v-img contain v-if="repository.repositoryType == 3" src="@/assets/ArtifactTypes/docker.png"></v-img>
            <v-img contain v-if="repository.repositoryType == 4" src="@/assets/ArtifactTypes/pypi.png"></v-img>
            <v-img contain v-if="repository.repositoryType == 5" src="@/assets/ArtifactTypes/bower.png"></v-img>
            <v-img contain v-if="repository.repositoryType == 6" src="@/assets/ArtifactTypes/helm.png"></v-img>
            <v-img contain v-if="repository.repositoryType == 7" src="@/assets/ArtifactTypes/raw.png"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="headline">{{ key }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.length }} Version(s) Promoted</v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.tags">
              <v-chip
                v-for="(tag, tagIndex) in item.tags"
                :key="tagIndex"
                class="ma-1 pa-3"
              >
                {{tag}}
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      </v-col>
    </v-row>
    <v-row>
        <v-col>
            <h2>Artifacts Pending</h2>
        </v-col>
    </v-row>
    <v-row v-if="!pendingPromotion || pendingPromotion.length == 0">
      <v-col>
        <p>No promoted artifacts were found for this repository</p>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col v-for="(item, index) in pendingPromotion" :key="index" cols="12" md="6">
      <v-card
          max-width="500"
          min-width="344"
          min-height="130"
        class="mx-auto ma-2"
      >
        <v-list-item>
          <v-list-item-avatar>
            <v-img contain v-if="repository.repositoryType == 0" src="@/assets/ArtifactTypes/nugetIcon.png"></v-img>
            <v-img contain v-if="repository.repositoryType == 1" src="@/assets/ArtifactTypes/npm.png"></v-img>
            <v-img contain v-if="repository.repositoryType == 2" src="@/assets/ArtifactTypes/maven.png"></v-img>
            <v-img contain v-if="repository.repositoryType == 3" src="@/assets/ArtifactTypes/docker.png"></v-img>
            <v-img contain v-if="repository.repositoryType == 4" src="@/assets/ArtifactTypes/pypi.png"></v-img>
            <v-img contain v-if="repository.repositoryType == 5" src="@/assets/ArtifactTypes/bower.png"></v-img>
            <v-img contain v-if="repository.repositoryType == 6" src="@/assets/ArtifactTypes/helm.png"></v-img>
            <v-img contain v-if="repository.repositoryType == 7" src="@/assets/ArtifactTypes/raw.png"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="headline">{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.version }} Version</v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.tags">
              <v-chip
                v-for="(tag, tagIndex) in item.tags"
                :key="tagIndex"
                class="ma-1 pa-3"
              >
                {{tag}}
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon @click="displayCancelPromotionDialog(item.id)">
            <v-icon>mdi-cancel</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="displayPromoteDialog" width="600">
      <v-card>
        <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>Promote Package</v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
            <v-row>
              <v-col>
                <p>This form will allow you to publish the selected package to the Innersourced HCA Repository</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field disabled readonly v-model="currentArtifact.name" label="Package Name"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select label="Select Version" :items="currentArtifactVersions" v-model="currentArtifact.selectedVersion" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea label="Description (Markdown supported)" v-model="currentArtifact.description"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-combobox
                  v-model="currentArtifact.tags"
                  hint="Type a tag and press enter. Maximum of 5."
                  :rules="tagRules"
                  label="Tags"
                  multiple
                  persistent-hint
                  small-chips
                >
                </v-combobox>
              </v-col>
            </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="displayPromoteDialog = false">Cancel</v-btn>
          <v-btn color="primary" @click="publishArtifactVersion" text :loading="isPromoting" :disabled="isPromoting">Publish</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'edit-repository-view',
  computed: {
    ...mapGetters(['adminSettings']),
    artifactDictionary: function () {
      if (this.artifacts) {
        let dict = {}
        this.artifacts.forEach(art => {
          if (dict[art.name]) {
            dict[art.name].push(art)
          } else {
            dict[art.name] = [
              art
            ]
          }
        })
        return dict
      }
      return { }
    },
    currentArtifactVersions: function () {
      if (this.currentArtifact && this.currentArtifact.versions) {
        return this.currentArtifact.versions.map(a => a.version)
      }
      return [ ]
    },
    promotedArtifactsDictionary: function () {
      if (this.promotedArtifacts) {
        let dict = {}
        this.promotedArtifacts.forEach(art => {
          if (dict[art.name]) {
            dict[art.name].push(art)
          } else {
            dict[art.name] = [
              art
            ]
          }
        })
        return dict
      }
      return { }
    }
  },
  data: () => ({
    isPromoting: false,
    displayPromoteDialog: false,
    teamId: '',
    repoId: '',
    isSubmitted: false,
    isDeletingRepository: false,
    isResettingPassword: false,
    showRepoPassword: false,
    repositoryType: [
      {
        key: 'Nuget',
        value: 0
      },
      {
        key: 'NPM',
        value: 1
      },
      {
        key: 'Maven',
        value: 2
      },
      {
        key: 'Docker',
        value: 3
      },
      {
        key: 'Pypi',
        value: 4
      },
      {
        key: 'Bower',
        value: 5
      },
      {
        key: 'Helm',
        value: 6
      },
      {
        key: 'Raw',
        value: 7
      }
    ],
    repository: {
      displayName: '',
      repositoryName: '',
      repositoryUrl: true,
      repositoryType: 0,
      isPrivate: true,
      serviceAccount: '',
      password: ''
    },
    artifacts: [ ],
    currentArtifact: { },
    promotedArtifacts: [ ],
    pendingPromotion: [ ],
    tagRules: [
      v => !v || v.length < 6 || 'Only 5 tags are allowed'
    ]
  }),
  methods: {
    copyMouseClick () {
      this.$store.commit('triggerSnack', { snackMessage: `Copied to clipboard`, snackColor: 'success' })
    },
    displayResetPasswordDialog: function () {
      this.$store.commit('showDialog', {
        message: 'Warning: Resetting this password will also reset all other repository password under this team, including different repository types. Are you sure you wish to proceed? This action cannot be undone.',
        confirmMethod: () => {
          this.resetPassword()
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    resetPassword: function () {
      this.isResettingPassword = true
      return axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories/${this.repoId}/password`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.getRepository()
            this.isResettingPassword = false
          },
          (error) => {
            this.isResettingPassword = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.resetPassword() })
          })
    },
    promoteArtifact: function (item, key) {
      this.currentArtifact = {
        name: key,
        versions: item,
        selectedVersion: ''
      }
      this.displayPromoteDialog = true
    },
    publishArtifactVersion: function () {
      this.isPromoting = true
      let promotionRequest = {
        sourceRepositoryId: this.repoId,
        artifactName: this.currentArtifact.name,
        artifactVersion: this.currentArtifact.selectedVersion,
        tags: this.currentArtifact.tags,
        description: this.currentArtifact.description
      }

      if (!this.validate()) {
        this.isPromoting = false
        return false
      }

      axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories/${this.repoId}/artifacts/promote`,
        {
          method: 'POST',
          data: JSON.stringify(promotionRequest),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.getPromotedArtifacts()
            this.getRepositoryArtifacts().then(() => {
              this.isPromoting = false
              this.displayPromoteDialog = false
            })
            this.getPendingPromotionArtifacts()
            if (response.data.status === 'pending') {
              this.$store.commit('triggerSnack', { snackMessage: 'The artifact is in a pending approval state', snackColor: 'warning' })
            } else if (response.data.status === 'approved') {
              this.$store.commit('triggerSnack', { snackMessage: 'The artifact has been updated successfully', snackColor: 'success' })
            }
          },
          (error) => {
            this.isPromoting = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.create() })
            return error
          })
    },
    validate () {
      let validated = true

      if (!this.$refs.form.validate()) {
        validated = false
      }
      return validated
    },
    getRepository: function () {
      return axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories/${this.repoId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.repository = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getRepository() })
          })
    },
    getRepositoryArtifacts: function () {
      return axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories/${this.repoId}/artifacts`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.artifacts = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getRepositoryArtifacts() })
          })
    },
    getPromotedArtifacts: function () {
      return axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories/${this.repoId}/artifacts/promote`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.promotedArtifacts = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getPromotedArtifacts() })
          })
    },
    getPendingPromotionArtifacts: function () {
      return axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories/${this.repoId}/artifacts/promote/pending`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.pendingPromotion = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getPendingPromotionArtifacts() })
          })
    },
    displayDeleteDialog: function () {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to delete this Repo? This action can not be undone.',
        confirmMethod: () => {
          this.deleteRepository()
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    deleteRepository: function () {
      this.isDeletingRepository = true
      return axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories/${this.repoId}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.$router.push(`/teams/${this.teamId}/`)
          },
          (error) => {
            this.isDeletingRepository = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteRepository() })
          })
    },
    displayCancelPromotionDialog: function (id) {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to cancel with promotion request? This action can not be undone and you will have to create a new request.',
        confirmMethod: () => {
          this.cancelPromotion(id)
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    cancelPromotion: function (id) {
      this.isDeletingProduct = true
      return axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories/artifacts/${id}/promote?actions=cancel`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.getPendingPromotionArtifacts()
            this.getRepositoryArtifacts()
          },
          (error) => {
            this.isDeletingProduct = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.cancelPromotion(id) })
          })
    },
    getTeam: function () {
      return axios(
        `${process.env.VUE_APP_REPO_BASEURL}/teams/${this.teamId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.team = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getTeam() })
          })
    }
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.teamId = this.$route.params.id
      this.repoId = this.$route.params.repoId
      this.getRepository().then(() => {
        this.getRepositoryArtifacts()
        this.getPromotedArtifacts()
        this.getPendingPromotionArtifacts()
        this.getTeam().then(() => {
          this.$store.commit('breadcrumbs', [
            {
              text: 'My Teams',
              exact: true,
              disabled: false,
              to: `/teams`
            },
            {
              text: this.team.displayName,
              exact: true,
              disabled: false,
              to: `/teams/${this.teamId}`
            },
            {
              text: 'My Repositories',
              exact: true,
              disabled: true
            },
            {
              text: this.repository.name,
              exact: true,
              disabled: false,
              to: `/teams/${this.teamId}/repositories/${this.repository.id}`
            }
          ])
        })
      })
    } else {
      alert('could not find repository as an ID was not provided in the url')
    }
  }
}
</script>

<style scoped>
  .user-table {
    border: 1px solid rgba(0, 0, 0, 0.4);
  }
  .key-table {
    /* border: 1px solid rgba(0, 0, 0, 0.4);
    padding: 5px; */
    margin: 2px;
    margin-bottom: 15px;
  }
  .user-error {
    font-size: 12px;
  }

  .api-name {
    margin-bottom: 10px;
  }

  .visible {
    margin-bottom: 8px;
  }

  .generate-token {
    margin-top:15px;
    padding-left: 5px;
    padding-right: 5px;
    min-width: 150px !important;
  }
#v-step-8 {
  max-width: 200px;
}

.lock-button {
  pointer-events: auto;
}

</style>
