<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h5">MU3 External Users:</span>
      </v-col>
    </v-row>
    <v-row id="v-step-5">
      <v-col cols="12">
        <v-data-table
          :headers="mu3UserHeaders"
          :items="mu3Users"
          :items-per-page="15"
          class="elevation-1"
          :loading="loadingUsers"
        >
        <template v-slot:item.isEnabled="{ item }">
            <v-chip
              v-if="!item.isEnabled"
              class="ma-2"
              color="red"
              text-color="white"
            >
              No
            </v-chip>
            <v-chip
              v-if="item.isEnabled"
              class="ma-2"
            >
              Yes
            </v-chip>
          </template>
        <template v-slot:item.isApproved="{ item }">
            <v-chip
              v-if="!item.isApproved"
              class="ma-2"
              color="red"
              text-color="white"
            >
              No
            </v-chip>
            <v-chip
              v-if="item.isApproved"
              class="ma-2"
            >
              Yes
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon @click="editMu3User(item)"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn icon @click="deleteMu3User(item)"><v-icon>mdi-delete</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="displayDialog" width="1500">
      <v-card>
        <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>MU3 External User Details</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col>
                    <v-text-field label="Email" disabled v-model="currentUser.emailAddress" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="First Name" disabled v-model="currentUser.firstName" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Address 1" disabled v-model="currentUser.address1" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Address 2" disabled v-model="currentUser.address2" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="City" disabled v-model="currentUser.city" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="State" disabled v-model="currentUser.state" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Zip Code" disabled v-model="currentUser.zipcode" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Is Approved" disabled v-model="currentUser.isApproved" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Is Enabled" disabled v-model="currentUser.isEnabled" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Is Verified" disabled v-model="currentUser.isVerified" />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col>
                    <v-text-field label="Legal Name of Business" disabled v-model="currentUser.legalNameOfBusiness" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Business Federal Tax ID" disabled v-model="currentUser.businessFederalTaxId" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Developer Organization" disabled v-model="currentUser.developerOrganization" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Primary Business Contact" disabled v-model="currentUser.primaryBusinessContact" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Primary Business Contact Email" disabled v-model="currentUser.primaryBusinessContactEmailAddress" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Primary Business Contact Title" disabled v-model="currentUser.primaryBusinessContactTitle" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Primary Business Contact Work Phone" disabled v-model="currentUser.primaryBusinessContactWorkPhone" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Primary Technical Contact" disabled v-model="currentUser.primaryTechnicalContact" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Primary Technical Contact Email" disabled v-model="currentUser.primaryTechnicalContactEmailAddress" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Primary Technical Contact Title" disabled v-model="currentUser.primaryTechnicalContactTitle" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Primary Technical Contact Work Phone" disabled v-model="currentUser.primaryTechnicalContactWorkPhone" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn v-if="!currentUser.isApproved" color="primary" :loading="isUpdatingUser" :disabled="isUpdatingUser" @click="approveUserDialog">Approve User</v-btn>
          <v-btn v-if="currentUser.isApproved" color="warning" :loading="isUpdatingUser" :disabled="isUpdatingUser" @click="disableUserDialog">Disable User</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="displayDialog = false">Cancel</v-btn>
          <v-btn color="primary" text @click="displayDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'mu3-users',
  data: () => ({
    displayDialog: false,
    loadingUsers: false,
    isUpdatingUser: false,
    mu3Users: [ ],
    currentUser: {
    },
    isEditMu3: false,
    mu3UserHeaders: [
      {
        text: 'Email',
        align: 'left',
        sortable: true,
        value: 'emailAddress'
      },
      {
        text: 'First Name',
        align: 'left',
        sortable: true,
        value: 'firstName'
      },
      {
        text: 'Last Name',
        align: 'left',
        sortable: true,
        value: 'lastName'
      },
      {
        text: 'Is Approved',
        align: 'left',
        sortable: true,
        value: 'isApproved'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: true,
        value: 'actions'
      }
    ]
  }),
  methods: {
    disableUserDialog: function () {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to disable this user? Their logins will be disabled.',
        confirmMethod: () => {
          this.disableUser()
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    disableUser: function () {
      this.isUpdatingUser = true
      axios(
        `${process.env.VUE_APP_BASEURL}/admin/mu3/users/${this.currentUser.id}/disable`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            this.getMu3Users().then(() => {
              this.isUpdatingUser = false
              this.displayDialog = false
            })
          },
          (error) => {
            this.isUpdatingUser = false
            this.displayDialog = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.enableUser() })
            return error
          })
    },
    approveUserDialog: function () {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to approve this user? Keys will be created for this user.',
        confirmMethod: () => {
          this.approveUser()
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    approveUser: function () {
      this.isUpdatingUser = true
      axios(
        `${process.env.VUE_APP_BASEURL}/admin/mu3/users/${this.currentUser.id}/approve`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            this.getMu3Users().then(() => {
              this.isUpdatingUser = false
              this.displayDialog = false
            })
          },
          (error) => {
            this.isUpdatingUser = false
            this.displayDialog = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.enableUser() })
            return error
          })
    },
    editMu3User: function (item) {
      this.isEditMu3 = true
      this.currentUser = item
      this.displayDialog = true
    },
    getMu3Users: async function () {
      this.loadingUsers = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/admin/mu3/users`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.mu3Users = response.data
            this.loadingUsers = false
          },
          (error) => {
            this.loadingUsers = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getMu3Users() })
          })
    }
  },
  mounted: function () {
    this.getMu3Users()
  }
}
</script>

<style scoped>

</style>
