<template>
  <v-container>
    <v-row>
      <v-col>
          <h2>Pending Subscription Requests:</h2>
      </v-col>
    </v-row>
    <v-row id="v-step-5">
      <v-col cols="12">
        <v-data-table
          :headers="requestHeaders"
          :items="requests"
          :items-per-page="15"
          class="elevation-1"
          v-if="!loadingRequests"
        >
          <template v-slot:item.createdDateTimeUtc="{ item }">
            <span>{{ getDate(item.meta.createdOnUtc) }}</span>
          </template>
          <template v-slot:item.requestedByUserId="{ item }">
            <span><a href="#" @click.stop="openUserDialog(item)">{{ getMu3User(item.requestedByUserId).emailAddress }}</a></span>
          </template>
          <template v-slot:item.externalUserAppId="{ item }">
            <span>{{ getMu3App(item.externalUserAppId) }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="primary" :loading="currentlyApproving.includes(item.id)" @click="approveRequestConfirmation(item)" class="mr-2 action-button">
                  <v-icon color="success">mdi-check</v-icon>
                </v-btn>
              </template>
              <span>Approve</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="error" :loading="currentlyDenying.includes(item.id)" @click="denyRequestConfirmation(item)" class="mr-2 action-button">
                  <v-icon color="error">mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Reject</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="info" :to="'/apis/' + item.apiId" target="_blank" class="action-button">
                  <v-icon>mdi-call-made</v-icon>
                </v-btn>
              </template>
              <span>Visit Api</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <v-col v-if="loadingRequests">
        <v-skeleton-loader
          class="mx-auto"
          type="table"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
        <v-col>
            <h2>Completed Subscription Requests:</h2>
        </v-col>
    </v-row>
    <v-row id="v-step-5">
      <v-col cols="12">
        <v-data-table
          :headers="completedRequestHeaders"
          :items="completedRequests"
          :items-per-page="15"
          class="elevation-1"
          v-if="!loadingCompletedRequests"
        >
          <template v-slot:item.requestedByUserId="{ item }">
            <span><a href="#" @click.stop="openUserDialog(item)">{{ getMu3User(item.requestedByUserId).emailAddress }}</a></span>
          </template>
          <template v-slot:item.externalUserAppId="{ item }">
            <span>{{ getMu3App(item.externalUserAppId) }}</span>
          </template>
          <template v-slot:item.createdDateTimeUtc="{ item }">
            <span>{{ getDate(item.meta.createdOnUtc) }}</span>
          </template>
          <template v-slot:item.completionTime="{ item }">
            <span>{{ getDate(item.completionTime) }}</span>
          </template>
          <template v-slot:item.approved="{ item }">
            <span v-if="item.approved === true">Approved</span>
            <span v-else-if="item.approved === false">Denied</span>
          </template>
          <template v-slot:item.status="{ item }">
            <span v-if="item.status === 0">Approved</span>
            <span v-else-if="item.status === 2">Denied</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="info" :to="'/apis/' + item.apiId" target="_blank" class="action-button">
                  <v-icon>mdi-call-made</v-icon>
                </v-btn>
              </template>
              <span>Visit Api</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <v-col v-if="loadingCompletedRequests">
        <v-skeleton-loader
          class="mx-auto"
          type="table"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
        <v-dialog v-model="displayApproveDialog" width="500">
      <v-card>
        <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>Confirmation Required</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <h3>You are about to approve an external user to subscribe to this api.</h3><br/>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="displayApproveDialog = false">Cancel</v-btn>
          <v-btn color="primary" @click="approveRequest" text>Approve</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="displayDenyDialog" width="500">
      <v-card>
        <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>Confirmation Required</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <p>Are you sure you wish to deny this subscription request? This action cannot be undone and the user will need to request the subscription again</p>
                <v-text-field v-model="currentRequest.reason" label="Reason" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="displayDenyDialog = false">Cancel</v-btn>
          <v-btn color="primary" text @click="denyRequest">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="displayUserDialog" width="1500">
      <v-card>
        <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>MU3 External User Details</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col>
                    <v-text-field label="Email" disabled v-model="currentUser.emailAddress" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="First Name" disabled v-model="currentUser.firstName" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Address 1" disabled v-model="currentUser.address1" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Address 2" disabled v-model="currentUser.address2" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="City" disabled v-model="currentUser.city" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="State" disabled v-model="currentUser.state" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Zip Code" disabled v-model="currentUser.zipcode" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Is Approved" disabled v-model="currentUser.isApproved" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Is Enabled" disabled v-model="currentUser.isEnabled" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Is Verified" disabled v-model="currentUser.isVerified" />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col>
                    <v-text-field label="Legal Name of Business" disabled v-model="currentUser.legalNameOfBusiness" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Business Federal Tax ID" disabled v-model="currentUser.businessFederalTaxId" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Developer Organization" disabled v-model="currentUser.developerOrganization" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Primary Business Contact" disabled v-model="currentUser.primaryBusinessContact" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Primary Business Contact Email" disabled v-model="currentUser.primaryBusinessContactEmailAddress" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Primary Business Contact Title" disabled v-model="currentUser.primaryBusinessContactTitle" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Primary Business Contact Work Phone" disabled v-model="currentUser.primaryBusinessContactWorkPhone" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Primary Technical Contact" disabled v-model="currentUser.primaryTechnicalContact" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Primary Technical Contact Email" disabled v-model="currentUser.primaryTechnicalContactEmailAddress" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Primary Technical Contact Title" disabled v-model="currentUser.primaryTechnicalContactTitle" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Primary Technical Contact Work Phone" disabled v-model="currentUser.primaryTechnicalContactWorkPhone" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="displayUserDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'mu3-subscriptions',
  data: () => ({
    displayUserDialog: false,
    currentlyApproving: [ ],
    currentlyDenying: [ ],
    loadingRequests: true,
    loadingCompletedRequests: true,
    completedRequests: [ ],
    currentUser: { },
    requests: [ ],
    requestHeaders: [
      {
        text: 'Api Name',
        align: 'left',
        sortable: true,
        value: 'apiName'
      },
      {
        text: 'Subscribing App Name',
        align: 'left',
        sortable: true,
        value: 'externalUserAppId'
      },
      {
        text: 'Requested By',
        align: 'left',
        sortable: true,
        value: 'requestedByUserId'
      },
      {
        text: 'Environment Name',
        align: 'left',
        sortable: true,
        value: 'environmentName'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: true,
        value: 'actions'
      }
    ],
    completedRequestHeaders: [
      {
        text: 'Api Name',
        align: 'left',
        sortable: true,
        value: 'apiName'
      },
      {
        text: 'Subscribing App Name',
        align: 'left',
        sortable: true,
        value: 'externalUserAppId'
      },
      {
        text: 'Requested By',
        align: 'left',
        sortable: true,
        value: 'requestedByUserId'
      },
      {
        text: 'Requested Time',
        align: 'left',
        sortable: true,
        value: 'createdDateTimeUtc'
      },
      {
        text: 'Environment',
        align: 'left',
        sortable: true,
        value: 'environmentName'
      },
      {
        text: 'Completed Time',
        align: 'left',
        sortable: true,
        value: 'completionTime'
      },
      {
        text: 'CompletedBy',
        align: 'left',
        sortable: true,
        value: 'completedBy'
      },
      {
        text: 'Reason',
        align: 'left',
        sortable: true,
        value: 'reason'
      },
      {
        text: 'Status',
        align: 'left',
        sortable: true,
        value: 'approved'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: true,
        value: 'actions'
      }
    ],
    displayApproveDialog: false,
    displayDenyDialog: false,
    reason: null,
    currentRequest: '',
    apps: [],
    mu3Users: []
  }),
  methods: {
    getMu3Apps: async function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/admin/mu3/apps`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.apps = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getMu3Apps() })
          })
    },
    getMu3Users: async function () {
      this.loadingUsers = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/admin/mu3/users`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.mu3Users = response.data
            this.loadingUsers = false
          },
          (error) => {
            this.loadingUsers = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getMu3Users() })
          })
    },
    getDate: function (date) {
      return moment(date).fromNow()
    },
    approveRequestConfirmation: function (request) {
      this.currentRequest = request
      this.displayApproveDialog = true
    },
    denyRequestConfirmation: function (request) {
      this.currentRequest = request
      this.displayDenyDialog = true
    },
    approveRequest: function () {
      this.displayApproveDialog = false
      this.currentRequest.approved = true
      this.currentlyApproving.push(this.currentRequest.id)
      return axios(
        `${process.env.VUE_APP_BASEURL}/admin/mu3/subscriptionRequests/${this.currentRequest.id}/complete`,
        {
          method: 'PUT',
          data: JSON.stringify(this.currentRequest),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.currentlyApproving = this.currentlyApproving.filter(x => {
              return x !== this.currentId
            })
            this.getPendingRequests()
            this.getCompletedRequests()
            this.currentId = null
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.approveRequest() })
            this.currentlyApproving = this.currentlyApproving.filter(x => {
              return x !== this.currentId
            })
          })
    },
    denyRequest: function () {
      this.displayDenyDialog = false
      this.currentRequest.approved = false
      this.currentlyDenying.push(this.currentRequest.id)
      return axios(
        `${process.env.VUE_APP_BASEURL}/admin/mu3/subscriptionRequests/${this.currentRequest.id}/complete`,
        {
          method: 'PUT',
          data: JSON.stringify(this.currentRequest),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.currentlyDenying = this.currentlyDenying.filter(x => {
              return x !== this.currentId
            })
            this.getPendingRequests()
            this.getCompletedRequests()
            this.currentId = null
            this.reason = null
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.denyRequest() })
            this.currentlyDenying = this.currentlyDenying.filter(x => {
              return x !== this.currentId
            })
          })
    },
    getPendingRequests: function () {
      this.loadingRequests = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/admin/mu3/subscriptionRequests/pending`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            var requests = response.data
            this.requests = requests
            this.loadingRequests = false
          },
          (error) => {
            this.loadingRequests = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getPendingRequests() })
          })
    },
    getCompletedRequests: function () {
      this.loadingCompletedRequests = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/admin/mu3/subscriptionRequests/complete`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.completedRequests = response.data
            this.loadingCompletedRequests = false
          },
          (error) => {
            this.loadingCompletedRequests = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getCompletedRequests() })
          })
    },
    openUserDialog: function (request) {
      this.currentUser = this.getMu3User(request.requestedByUserId)
      this.displayUserDialog = true
    },
    getMu3User: function (id) {
      return this.mu3Users.find(x => x.id === id)
    },
    getMu3App: function (id) {
      return this.apps.find(x => x.id === id).displayName
    }
  },
  mounted: function () {
    this.getMu3Apps().then(() => {
      this.getMu3Users().then(() => {
        this.getPendingRequests()
        this.getCompletedRequests()
      })
    })
  }
}
</script>

<style scoped>

</style>
