<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h5">MU3 Products:</span>
      </v-col>
    </v-row>
    <v-row id="v-step-5">
      <v-col cols="12">
        <v-data-table
          :headers="mu3ProductHeaders"
          :items="products"
          :items-per-page="15"
          class="elevation-1"
          :loading="loadingProducts"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="info" :to="'/products/' + item.id" target="_blank" class="action-button">
                  <v-icon>mdi-call-made</v-icon>
                </v-btn>
              </template>
              <span>Visit Product</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'mu3-products',
  data: () => ({
    loadingProducts: false,
    products: [ ],
    mu3ProductHeaders: [
      {
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'displayName'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: true,
        value: 'actions'
      }
    ]
  }),
  methods: {
    getMu3Products: async function () {
      this.loadingProducts = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/admin/mu3/products`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.products = response.data
            this.loadingProducts = false
          },
          (error) => {
            this.loadingProducts = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getMu3Products() })
          })
    }
  },
  mounted: function () {
    this.getMu3Products()
  }
}
</script>

<style scoped>

</style>
