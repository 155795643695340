<template>
  <div>
    <v-card class="mx-auto mt-9"
            max-width="550">
      <template v-if="!completedSurvey && loaded && !this.survey.isClosed">
        <v-card-text class="pa-6 pb-2">
          <v-label>On a scale of 1-5, how <strong>unhappy</strong> to <strong>happy</strong> are you leveraging GitHub Copilot this week?</v-label>
          <v-radio-group
            v-model="selectedRating"
            row
          >
            <v-layout class="mt-5 w-100">
              <v-flex class="d-flex flex-column fixed-width"
                      @click="selectedRating = rating.title"
                      v-for="(rating, index) in ratings" :key="rating.title">
                <v-flex
                  class="d-flex justify-content-center"
                >
                  <div :class="selectedRating && rating.title === selectedRating ? 'selected': 'not-selected'">{{ index + 1 }}</div>
                  <v-img v-if="selectedRating && rating.title === selectedRating" max-height="50" max-width="50" :src="require('@/assets/pulseBotImages/' + rating.src + '-selected.png')"></v-img>
                  <v-img v-if="!selectedRating || (selectedRating && rating.title !== selectedRating)" max-height="50" max-width="50" :src="require('@/assets/pulseBotImages/' + rating.src + '.png')"></v-img>
                </v-flex>
                <v-flex class="d-flex flex-column text-center mt-1 align-radios">
                  <v-radio color="#2075AD" class="radio-height"
                           :value="rating.title"
                  >
                  </v-radio>
                  <v-label v-if="index === 0 || index === ratings.length - 1">
                    {{ rating.title }}
                  </v-label>
                </v-flex>
              </v-flex>
            </v-layout>
          </v-radio-group>
          <v-label>Please elaborate the reason for your rating below.</v-label>
          <v-textarea hide-details height="80" class="mt-2" solo label="Tell us how we can improve" v-model="comment"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn class="mb-2 mr-2" :disabled="ratingNumber === null || isSubmitting" :loading="isSubmitting" color="primary" @click="submitSurvey">Submit</v-btn>
        </v-card-actions>
      </template>
      <template v-if="completedSurvey && loaded && !this.survey.isClosed">
        <v-card-title>
          Thank you for participating in our survey today!
        </v-card-title>
        <v-card-subtitle>Surveys are on a weekly basis. Watch the Accelerated Technologies webex space next week for a new link!</v-card-subtitle>
      </template>
      <template v-if="loaded && this.survey.isClosed">
        <v-card-title>
          Survey closed.
        </v-card-title>
        <v-card-subtitle>
          Thank you for your feedback. This survey has been closed but a new one will be posted soon. Please check the Accelerated Technologies Webex Space frequently for a new link
        </v-card-subtitle>
      </template>
    </v-card>
    <v-progress-circular
      v-if="!loaded"
      class="d-flex mr-auto ml-auto mt-9"
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'surveyQuestion',
  computed: {
    ratingNumber: {
      get: function () {
        if (this.selectedRating) {
          var rating = this.ratings.find(x => x.title === this.selectedRating)
          if (rating) {
            return rating.rating
          }
        }
        return null
      }
    }
  },
  data: () => ({
    ratings: [{ title: 'Very Unhappy',
      src: 'veryUnhappy',
      rating: 1 },
    { title: 'Unhappy',
      src: 'unhappy',
      rating: 2 },
    { title: 'Neutral',
      src: 'neutral',
      rating: 3 },
    { title: 'Happy',
      src: 'happy',
      rating: 4 },
    { title: 'Very Happy',
      src: 'veryHappy',
      rating: 5 }],
    selectedRating: null,
    comment: '',
    date: null,
    survey: null,
    surveyId: null,
    completedSurvey: false,
    loaded: false,
    isSubmitting: false
  }),
  created: function () {
    this.surveyId = this.$route.params.surveyId
    this.checkIfSurveyDone()
  },
  methods: {
    submitSurvey: function () {
      this.isSubmitting = true
      axios(
        `${process.env.VUE_APP_BASEURL}/pulsesurvey/response`,
        {
          method: 'POST',
          data: {
            rating: this.ratingNumber,
            comment: this.comment,
            surveyId: this.surveyId
          },
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.completedSurvey = true
            this.isSubmitting = false
          },
          (error) => {
            this.isSubmitting = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.submitSurvey() })
            return error
          })
    },
    // checks if the user has already submitted a response
    checkIfSurveyDone: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/pulsesurvey/check/${this.surveyId}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.completedSurvey = response.data
            this.loaded = true
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.checkIfSurveyDone() })
            return error
          })
    },
    getSurvey: function () {
      axios(
        `${process.env.VUE_APP_BASEURL}/pulsesurvey/surveys/${this.surveyId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.survey = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.checkIfSurveyDone() })
            return error
          })
    }
  },
  beforeMount: function () {
    this.getSurvey()
  }
}
</script>

<style scoped>
.justify-content-center {
  justify-content: center;
  height: 50px;
}
.selected {
  color: #2075AD !important;
}
.radio-height {
  height: 40px !important;
  justify-content: center;
  margin-right: 0 !important;
  margin-left: 18px;
}
.not-selected {
  color: rgba(0, 0, 0, 0.54) !important;
}
.fixed-width {
  width: calc(20%);
}
.align-radios {
  height: 70px;
}
</style>
