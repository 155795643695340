<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h5">External Site Settings:</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <v-row>
          <v-col>
            <v-switch disabled v-model="settings.isMaintenanceMode" label="Maintenance Mode Enabled" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch disabled v-model="settings.isSignupEnabled" label="Signup Enabled" />
          </v-col>
        </v-row>
        <v-row style="margin-top: 8px">
          <v-col>
            <v-textarea v-model="settings.termsOfUse" label="Terms of Use" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="6">
        <v-row>
          <v-col>
            <v-text-field v-model="settings.contactUsEmail" label="Contact Us Email" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field v-model="settings.contactUsPhoneNumber" label="Contact Us Phone Number" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea v-model="settings.privacyPolicy" label="Privacy Policy" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span class="text-h5">FAQs:</span>
        <br/>
        <v-btn class="mt-2" @click="editOrAddFaq()" :disabled="isLoading" color="info">Add FAQ</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="faqHeaders"
          :items="settings.faq"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:item.order="{ item }">
            <v-btn v-if="settings.faq.indexOf(item) !== 0" icon @click="decrementOrder(item)"><v-icon>mdi-arrow-up</v-icon></v-btn>
            <v-btn v-if="settings.faq.indexOf(item) !== settings.faq.length-1" icon @click="incrementOrder(item)"><v-icon>mdi-arrow-down</v-icon></v-btn>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon @click="editOrAddFaq(item)"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn icon @click="deleteFaq(item)"><v-icon>mdi-delete</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn :loading="isLoading" :disabled="isLoading" color="primary" @click="updateSettings">Update Settings</v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="displayDialog" width="1500">
      <v-card>
        <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>Add/Edit FAQ</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-textarea label="Question" v-model="currentFaq.question" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea label="Answer" v-model="currentFaq.answer" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="displayDialog = false">Cancel</v-btn>
          <v-btn color="primary" text @click="saveFaq">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'mu3-settings',
  data: () => ({
    isLoading: false,
    displayDialog: false,
    currentFaq: { },
    settings: {
      faq: [],
      termsAndConditions: '',
      contactUsEmail: '',
      termsOfUse: '',
      privacyPolicy: '',
      isMaintenanceMode: false,
      isSignupEnabled: false
    },
    faqHeaders: [
      {
        text: 'Question',
        align: 'start',
        sortable: false,
        value: 'question'
      },
      {
        text: 'Answer',
        align: 'start',
        sortable: false,
        value: 'answer'
      },
      {
        text: 'Order',
        align: 'start',
        sortable: false,
        value: 'order'
      },
      {
        text: 'Actions',
        align: 'start',
        sortable: false,
        value: 'actions'
      }
    ]
  }),
  methods: {
    getIndex: function () {

    },
    getSiteSettings: function () {
      this.isLoading = true
      axios(
        `${process.env.VUE_APP_BASEURL}/admin/mu3/settings`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            if (response.data) {
              this.settings = response.data
            }
            this.isLoading = false
          },
          (error) => {
            this.isLoading = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getSiteSettings() })
            return error
          })
    },
    updateSettings: function () {
      this.isLoading = true
      axios(
        `${process.env.VUE_APP_BASEURL}/admin/mu3/settings`,
        {
          method: 'PUT',
          data: JSON.stringify(this.settings),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.settings = response.data
            this.isLoading = false
          },
          (error) => {
            this.isLoading = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.updateSettings() })
            return error
          })
    },
    saveFaq: function () {
      if (!this.currentFaq.isExisting) {
        this.settings.faq.push(JSON.parse(JSON.stringify(this.currentFaq)))
        this.currentFaq = {}
      }
      this.displayDialog = false
    },
    editOrAddFaq: function (item) {
      if (item) {
        this.currentFaq = item
        this.currentFaq.isExisting = true
      } else {
        this.currentFaq = JSON.parse(JSON.stringify({ }))
      }
      this.displayDialog = true
    },
    deleteFaq: function (item) {
      // Find existing item index
      const index = this.settings.faq.indexOf(item)
      const ite = this.settings.faq.splice(index, 1)[0]
    },
    incrementOrder: function (item) {
      // Find existing item index
      const index = this.settings.faq.indexOf(item)

      // Only do this if the item is in the middle not at the end
      if (index !== this.settings.faq.length - 1) {
        const copy = [...this.settings.faq]
        const valueToMove = copy.splice(index, 1)[0]
        copy.splice(index + 1, 0, valueToMove)
        this.settings.faq = copy
      }
    },
    decrementOrder: function (item) {
      // Find existing item index
      const index = this.settings.faq.indexOf(item)

      // Only do this if the item is in the middle not at the start
      if (index !== 0) {
        const copy = [...this.settings.faq]
        const valueToMove = copy.splice(index, 1)[0]
        copy.splice(index - 1, 0, valueToMove)
        this.settings.faq = copy
      }
    }
  },
  mounted: function () {
    this.getSiteSettings()
  }
}
</script>

<style scoped>

</style>
