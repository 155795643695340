<template>
    <v-container>
      <v-row>
          <v-col>
              <h2>Services</h2>
          </v-col>
      </v-row>
      <v-row>
        <v-col class="mt-2" cols="12" md="2">
          <v-select
            solo
            :items="items"
            v-model="search"
            label="Cloud"
          ></v-select>
        </v-col>
        <v-col class="mt-2" cols="12" md="8">
          <v-text-field
            solo
            clearable
            v-model="cloud"
            label="Search"
            :items="items"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2" align="center" class="mt-2">
          <v-btn class="mb-1" large @click="displayServiceDialog = true" color="info">Add New</v-btn>
        </v-col>
      </v-row>
      <v-row id="v-step-5">
          <v-col cols="12">
              <v-data-table
                      :headers="pendingServiceHeaders"
                      :items="pendingServices"
                      :items-per-page="15"
                      class="elevation-1"
                      :search="search"
                      :custom-filter="customSearch"
                      v-if="!loadingService"
              >
              <template v-slot:item.actions="{ item }">
                <v-btn icon :disabled="modifyingItems.includes(item.id)" :loading="modifyingItems.includes(item.id)" @click="deleteService(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              </v-data-table>
          </v-col>
      </v-row>
      <v-dialog v-model="displayServiceDialog" width="1500">
        <v-card>
          <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>Add a Service</v-card-title>
          <v-card-text>
            <!-- add service section -->
            <v-container>
              <!-- first select box determines what is loaded in the second select box -->
              <v-select
                :items="cloudProviders"
                label="Standard"
                v-model="selectedCloudProvider"
              ></v-select>
              <v-select
                :items="availableCloudServices.data"
                item-text="title"
                return-object
                label="Standard"
                v-model="chosenCloudServices"
                multiple
                :menu-props="{ maxHeight: '400' }"
                v-if="availableCloudServices.length != 0"
              ></v-select>
            </v-container>
            <!-- end add service section -->
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- save and cancel btns -->
            <v-btn text @click="cancelAddService">Cancel</v-btn>
            <v-btn color="primary" :loading="savingService" :disabled="savingService" text @click="confirmAddService">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- if user tries to add a service from the 'All list' that already exists on the pending services list -->
      <v-dialog v-model="displayFailedDialog" width="1500">
        <v-card>
          <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>Error</v-card-title>
          <v-card-text>
            <v-container>
              <h1>Changes canceled.</h1>
              <br>
              <p>Some of the options chosen already exist in the selected list.</p>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelAddService">Accept</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['adminSettings']),
    pendingServiceHeaders () {
      return [
        {
          text: 'cloudType',
          align: 'left',
          sortable: true,
          value: 'cloudType'
        },
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'title',
          filter: value => {
            if (!this.cloud) return true
            return value.toLowerCase().includes(this.cloud.toLowerCase())
          }
        },
        {
          text: 'Actions',
          align: 'left',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },
  watch: {
    selectedCloudProvider: function (selectedCloudProvider) {
      this.getServices(selectedCloudProvider)
    }
  },
  data: () => ({
    cloud: '',
    search: '',
    page: 0,
    limit: 10,
    selectedCloudProvider: '',
    cloudProviders: ['GCP', 'Azure'],
    availableCloudServices: [],
    chosenCloudServices: [],
    items: [ 'All', 'GCP', 'Azure' ],
    savingService: false,
    loadingService: false,
    pendingServices: [ ],
    modifyingItems: [ ],
    displayServiceDialog: false,
    displayFailedDialog: false
  }),
  methods: {
    // Fires when a cloud type is selected in the first drop down
    getServices: function (selectedCloudProvider) {
      // If the user makes a new cloud selection reset the second drop down to be empty
      this.availableCloudServices = []
      if (selectedCloudProvider === 'GCP') {
        return axios(
          `${process.env.VUE_APP_CLOUDBASEURL}/cloud/services/GCP`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
          })
          .then(
            (response) => {
              this.availableCloudServices = response.data
            },
            (error) => {
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getServices() })
            })
      }
    },
    deleteService: function (item) {
      axios(
        `${process.env.VUE_APP_CLOUDBASEURL}/cloud/services/${item.id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.modifyingItems.push(item.id)
            this.getUpdatedServices()
          },
          (error) => {
            this.modifyingItems = this.modifyingItems.filter(x => {
              return x !== item.id
            })
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteOrganization() })
            return error
          })
    },
    // Fires when user hits save on the popup modal, posts to azure DB
    confirmAddService: function () {
      this.displayServiceDialog = false
      let duplicateFound = this.checkForDuplicates()
      if (duplicateFound === true) {
        this.displayFailedDialog = true
      } else {
        for (var service of this.chosenCloudServices) {
          axios(
            `${process.env.VUE_APP_CLOUDBASEURL}/cloud/services`,
            {
              method: 'POST',
              data: JSON.stringify(service),
              headers: { 'Content-Type': 'application/json' }
            })
            .then(
              (response) => {
                this.getUpdatedServices()
              },
              (error) => {
                this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.confirmAddService() })
                return error
              })
        }
      }
      this.chosenCloudServices = null
      this.selectedCloudProvider = null
    },
    getUpdatedServices: function () {
      axios(
        `${process.env.VUE_APP_CLOUDBASEURL}/cloud/services?page=${this.page}&limit=${this.limit}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.pendingServices = response.data.items
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getUpdatedServices() })
            return error
          })
    },
    customSearch: function (value, search, item) {
      if (search === 'All') return this.pendingServices
      return value != null && search != null && typeof value === 'string' && value.toString().indexOf(search) !== -1
    },
    cancelAddService: function () {
      this.displayServiceDialog = false
      this.displayFailedDialog = false
    },
    // checks the ui to see if object is in the main table already
    checkForDuplicates: function () {
      for (var i = 0; i < this.pendingServices.length; i++) {
        for (var j = 0; j < this.chosenCloudServices.length; j++) {
          if (this.pendingServices[i].description === this.chosenCloudServices[j].description) {
            return true
          }
        }
      }
      return false
    }
  },
  mounted: function () {
    this.getUpdatedServices()
  }
}
</script>

<style scoped>

</style>
