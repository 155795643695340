<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h5">MU3 External Apps:</span>
      </v-col>
    </v-row>
    <v-row id="v-step-5">
      <v-col cols="12">
        <v-data-table
          :headers="mu3UserHeaders"
          :items="apps"
          :items-per-page="15"
          class="elevation-1"
          :loading="loadingApps"
        >
        <template v-slot:item.isEnabled="{ item }">
            <v-chip
              v-if="!item.isEnabled"
              class="ma-2"
              color="red"
              text-color="white"
            >
              No
            </v-chip>
            <v-chip
              v-if="item.isEnabled"
              class="ma-2"
            >
              Yes
            </v-chip>
          </template>
        <template v-slot:item.isApproved="{ item }">
            <v-chip
              v-if="!item.isApproved"
              class="ma-2"
              color="red"
              text-color="white"
            >
              No
            </v-chip>
            <v-chip
              v-if="item.isApproved"
              class="ma-2"
            >
              Yes
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon :disabled="isUpdatingApp" @click="editMu3App(item)"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn icon :disabled="isUpdatingApp" @click="deleteAppDialog(item)"><v-icon>mdi-delete</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="displayDialog" width="1500">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card>
          <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>MU3 External User Details</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-row>
                    <v-col>
                      <v-text-field label="Name" disabled v-model="currentApp.displayName" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch label="Is Approved" v-model="currentApp.isApproved" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span>Redirect Urls:</span>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                Url
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="item in currentApp.redirectUrls"
                              :key="item"
                            >
                              <td>{{ item }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span>Allowed Users:</span>
                      <v-row>
                        <v-col cols="12" lg="10">
                        <v-select solo :items="mu3Users" v-model="newUser" item-text="emailAddress" item-value="id" />
                        </v-col>
                        <v-col cols="12" lg="2">
                        <v-btn @click="addUser" large>Add</v-btn>
                        </v-col>
                      </v-row>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                User
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="item in currentApp.allowedUsers"
                              :key="item"
                            >
                              <td>{{ getUserName(item) }}</td>
                              <td><v-btn icon @click="removeUser(item)"><v-icon>mdi-delete</v-icon></v-btn></td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field :rules="requiredRule" label="Client ID" v-model="currentApp.clientId" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="primary" :loading="isUpdatingApp" :disabled="isUpdatingApp" @click="updateAppDialog">Save Changes</v-btn>
            <v-spacer></v-spacer>
            <v-btn text @click="displayDialog = false">Cancel</v-btn>
            <v-btn color="primary" text @click="displayDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'mu3-apps',
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'This field is required'
    ],
    displayDialog: false,
    loadingApps: false,
    isUpdatingApp: false,
    newUser: null,
    apps: [ ],
    currentApp: {
    },
    mu3Users: [ ],
    isEditMu3: false,
    mu3UserHeaders: [
      {
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'displayName'
      },
      {
        text: 'Is Approved',
        align: 'left',
        sortable: true,
        value: 'isApproved'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: true,
        value: 'actions'
      }
    ]
  }),
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    addUser: function () {
      if (!this.currentApp.allowedUsers) {
        this.currentApp.allowedUsers = [ ]
      }
      if (!this.currentApp.allowedUsers.find(x => x === this.newUser)) {
        this.currentApp.allowedUsers.push(this.newUser)
      }
      this.newUser = null
    },
    removeUser: function (user) {
      this.currentApp.allowedUsers.indexOf(user)
      this.currentApp.allowedUsers.splice(user, 1)
    },
    getUserName: function (guid) {
      const user = this.mu3Users.find(x => x.id === guid)
      return user.emailAddress
    },
    updateAppDialog: function () {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to update this app? If the app is marked as disabled, any created keys will be deleted along with api subscriptions',
        confirmMethod: () => {
          this.updateApp()
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    updateApp: function () {
      this.isUpdatingApp = true
      axios(
        `${process.env.VUE_APP_BASEURL}/admin/mu3/apps/${this.currentApp.id}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify(this.currentApp)
        })
        .then(
          () => {
            this.getMu3Apps().then(() => {
              this.isUpdatingApp = false
              this.displayDialog = false
            })
          },
          (error) => {
            this.isUpdatingApp = false
            this.displayDialog = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.enableUser() })
            return error
          })
    },
    getExternalUsers: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/admin/mu3/users`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.mu3Users = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getMu3Users() })
          })
    },
    editMu3App: function (item) {
      this.isEditMu3 = true
      this.currentApp = item
      this.displayDialog = true
    },
    getMu3Apps: async function () {
      this.loadingApps = true
      return axios(
        `${process.env.VUE_APP_BASEURL}/admin/mu3/apps`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.apps = response.data
            this.loadingApps = false
          },
          (error) => {
            this.loadingApps = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getMu3Apps() })
          })
    },
    deleteAppDialog: function (app) {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to delete this app? Keys will be deleted along with all subscriptions for this app.',
        confirmMethod: () => {
          this.deleteMu3App(app)
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    deleteMu3App: function (app) {
      this.isUpdatingApp = true
      axios(
        `${process.env.VUE_APP_BASEURL}/admin/mu3/apps/${app.id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            this.getMu3Apps().then(() => {
              this.isUpdatingApp = false
            })
          },
          (error) => {
            this.isUpdatingApp = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteMu3App() })
            return error
          })
    }
  },
  mounted: function () {
    this.getExternalUsers().then(() => {
      this.getMu3Apps()
    })
  }
}
</script>

<style scoped>
.add_user {
  font-size: 12pt;
  font-weight: bold;
}
</style>
