<template>
    <v-container>
        <v-row justify="space-between">
            <v-col cols="8">
                <h2>Pending Cloud Accounts</h2>
            </v-col>
            <v-col>
            <v-text-field
                class="mb-5 mt-10"
                v-model="currentApprovalSearch"
                append-icon="mdi-magnify"
                label="Search"
                solo
                clearable
                hide-details
              ></v-text-field>
            </v-col>
        </v-row>
        <v-row id="v-step-5">
          <v-col cols="12">
              <!-- pending cloud table (top table) -->
              <v-data-table
                :headers="pendingCloudAccountHeaders"
                :items="pendingCloudAccounts"
                :items-per-page="currentApprovalLimit"
                :server-items-length="currentApprovalCount"
                :options.sync="approvalOptions"
                :loading="loadingCloudAccounts"
                class="elevation-1"
              >
                <!-- the last three headers -->
                <template v-slot:item.productId="{ item }">
                  <span>{{ item.productName }}</span>
                </template>
                <template v-slot:item.createdDateTimeUtc="{ item }">
                  <span>{{ getDate(item.approvalDetails.createdDateTimeUtc) }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <!-- action options here -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon color="primary" :loading="currentlyApproving.includes(item.id)" @click="approvePendingCloudAccountConfirmation(item.id)" class="mr-2 action-button">
                        <v-icon color="success">mdi-check</v-icon>
                      </v-btn>
                    </template>
                    <span>Approve</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon color="error" :loading="currentlyDenying.includes(item.id)" @click="denyPendingCloudAccountConfirmation(item.id)" class="mr-2 action-button">
                        <v-icon color="error">mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <span>Reject</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon color="info" :to="'/apis/' + item.apiId" target="_blank" class="action-button">
                        <v-icon>mdi-call-made</v-icon>
                      </v-btn>
                    </template>
                    <span>Visit Api</span>
                  </v-tooltip>
                  <!-- end action options -->
                </template>
              </v-data-table>
          </v-col>
        </v-row>
        <v-row justify="space-between">
            <v-col cols="8">
                <h2>Completed Cloud Account Requests</h2>
            </v-col>
            <v-col>
            <v-text-field
                class="mb-5 mt-10"
                v-model="currentCompletedSearch"
                append-icon="mdi-magnify"
                label="Search"
                solo
                clearable
                hide-details
              ></v-text-field>
            </v-col>
        </v-row>
        <v-row id="v-step-5">
            <v-col cols="12">
                <v-data-table
                  :headers="completedCloudAccountRequestHeaders"
                  :items="completedCloudAccountRequests"
                  :items-per-page="currentCompletedLimit"
                  :server-items-length="currentCompletedCount"
                  :options.sync="completedOptions"
                  class="elevation-1"
                  :loading="loadingCompletedCloudAccounts"
                >
                  <template v-slot:item.productId="{ item }">
                    <span>{{ item.productName }}</span>
                  </template>
                  <template v-slot:item.createdDateTimeUtc="{ item }">
                    <span>{{ getDate(item.approvalDetails.createdDateTimeUtc) }}</span>
                  </template>
                  <template v-slot:item.approvalDetails.completedDateTime="{ item }">
                    <span>{{ getDate(item.approvalDetails.completedDateTime) }}</span>
                  </template>
                  <template v-slot:item.approvalDetails.status="{ item }">
                    <span v-if="item.approvalDetails.status === 1">Approved</span>
                    <span v-else-if="item.approvalDetails.status === 2">Denied</span>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon color="info" :to="`/products/${item.evolveMarketplaceProductId}/cloud/accounts/${item.id}`" target="_blank" class="action-button">
                          <v-icon>mdi-call-made</v-icon>
                        </v-btn>
                      </template>
                      <span>See Deployment Status</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
            </v-col>
        </v-row>
        <!-- confirm approve card -->
        <v-dialog v-model="displayApproveDialog" width="500">
          <v-card>
            <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>Confirmation Required</v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12">
                    <h3>Please provide a reason for approval</h3><br/>
                    <v-text-field v-model="reason" label="Reason" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="displayApproveDialog = false">Cancel</v-btn>
              <v-btn color="primary" @click="approvePendingCloudAccount" text>Approve</v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
      <v-dialog v-model="displayDenyDialog" width="500">
        <!-- confirm delete card -->
        <v-card>
          <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>Confirmation Required</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <p>Are you sure you wish to deny this cloud account request? This action cannot be undone and the user will need to request approval again</p>
                  <v-text-field v-model="reason" label="Reason" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="displayDenyDialog = false">Cancel</v-btn>
            <v-btn color="primary" text @click="denyPendingCloudAccount">Confirm</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  watch: {
    approvalOptions: {
      handler () {
        this.currentApprovalPage = this.approvalOptions.page
        this.getPendingCloudAccounts()
      },
      deep: true
    },
    completedOptions: {
      handler () {
        this.currentCompletedPage = this.completedOptions.page
        this.getPendingCloudAccounts()
      },
      deep: true
    },
    currentApprovalSearch: function () {
      clearTimeout(this.currentApprovalsearchTimeout)

      // Make a new timeout set to go off in 800ms
      this.currentApprovalsearchTimeout = setTimeout(() => {
        if (this.currentApprovalSearch == null) {
          this.currentApprovalSearch = ''
        }
        this.currentApprovalPage = 1
        this.getPendingCloudAccounts()
      }, 500)
    },
    currentCompletedSearch: function () {
      clearTimeout(this.currentCompletedsearchTimeout)

      // Make a new timeout set to go off in 800ms
      this.currentCompletedsearchTimeout = setTimeout(() => {
        if (this.currentCompletedsearchTimeout == null) {
          this.currentCompletedsearchTimeout = ''
        }
        this.currentCompletedPage = 1
        this.getCompletedCloudAccounts()
      }, 500)
    }
  },
  data: () => ({
    currentCompletedsearchTimeout: null,
    currentApprovalsearchTimeout: null,
    approvalOptions: {},
    completedOptions: {},
    loadingCloudAccounts: false,
    loadingCompletedCloudAccounts: false,
    currentlyApproving: [ ],
    currentlyDenying: [ ],
    pendingCloudAccounts: [ ],
    pendingCloudAccountHeaders: [
      {
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'displayName'
      },
      {
        text: 'Product',
        align: 'left',
        sortable: true,
        value: 'entityName'
      },
      {
        text: 'Requestor',
        align: 'left',
        sortable: true,
        value: 'approvalDetails.createdBy'
      },
      {
        text: 'Date & Time',
        align: 'left',
        sortable: true,
        value: 'createdDateTimeUtc'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: true,
        value: 'actions'
      }
    ],
    completedCloudAccountRequests: [ ],
    completedCloudAccountRequestHeaders: [
      {
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'displayName'
      },
      {
        text: 'Product',
        align: 'left',
        sortable: true,
        value: 'entityName'
      },
      {
        text: 'Requested Datetime',
        align: 'left',
        sortable: true,
        value: 'createdDateTimeUtc'
      },
      {
        text: 'Completed Datetime',
        align: 'left',
        sortable: true,
        value: 'approvalDetails.completedDateTime'
      },
      {
        text: 'Reason',
        align: 'left',
        sortable: true,
        value: 'approvalDetails.reason'
      },
      {
        text: 'Status',
        align: 'left',
        sortable: true,
        value: 'approvalDetails.status'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: true,
        value: 'actions'
      }
    ],
    displayApproveDialog: false,
    displayDenyDialog: false,
    reason: null,
    currentId: '',
    currentApprovalPage: 0,
    currentApprovalLimit: 10,
    currentCompletedPage: 0,
    currentCompletedLimit: 10,
    currentApprovalSearch: '',
    currentCompletedSearch: '',
    currentCompletedCount: 0,
    currentApprovalCount: 0
  }),
  methods: {
    getDate: function (date) {
      return moment(date).fromNow()
    },
    getUserDetails: async function (username) {
      return axios(
        `${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/search/user?searchText=${username}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getUserDetails() })
          })
    },
    approvePendingCloudAccountConfirmation: function (deploymentId) {
      this.reason = null
      this.currentId = deploymentId
      this.displayApproveDialog = true
    },
    denyPendingCloudAccountConfirmation: function (deploymentId) {
      this.reason = null
      this.currentId = deploymentId
      this.displayDenyDialog = true
    },
    approvePendingCloudAccount: function () {
      this.displayApproveDialog = false
      return axios(
        `${process.env.VUE_APP_CLOUDBASEURL}/cloud/accounts/${this.currentId}/approve?reason=${this.reason}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.currentlyApproving = this.currentlyApproving.filter(x => {
              return x !== this.currentId
            })
            this.getPendingCloudAccounts()
            this.getCompletedCloudAccounts()
            this.currentId = null
            this.reason = null
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.approvePendingCloudAccount() })
            this.currentlyApproving = this.currentlyApproving.filter(x => {
              return x !== this.currentId
            })
          })
    },
    denyPendingCloudAccount: function () {
      this.displayDenyDialog = false
      return axios(
        `${process.env.VUE_APP_CLOUDBASEURL}/cloud/accounts/${this.currentId}/reject?reason=${this.reason}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.currentlyDenying = this.currentlyDenying.filter(x => {
              return x !== this.currentId
            })
            this.getPendingCloudAccounts()
            this.getCompletedCloudAccounts()
            this.currentId = null
            this.reason = null
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.denyPendingCloudAccount() })
            this.currentlyDenying = this.currentlyDenying.filter(x => {
              return x !== this.currentId
            })
          })
    },
    getPendingCloudAccounts: function (name) {
      this.loadingCloudAccounts = true
      return axios(
        `${process.env.VUE_APP_CLOUDBASEURL}/cloud/accounts/pending?page=${this.currentApprovalPage}&limit=${this.currentApprovalLimit}&search=${this.currentApprovalSearch}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.pendingCloudAccounts = response.data.items
            this.currentApprovalCount = Math.ceil(response.data.totalCount / this.currentApprovalLimit)
            this.pendingCloudAccounts.forEach(x => {
              this.getUserDetails(x.approvalDetails.createdBy).then((y) => {
                x.approvalDetails.createdBy = y[0].Value
              })
            })
            this.loadingCloudAccounts = false
          },
          (error) => {
            this.loadingCloudAccounts = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getPendingCloudAccounts() })
          })
    },
    getCompletedCloudAccounts: function (name, status) {
      this.loadingCompletedCloudAccounts = true
      return axios(
        `${process.env.VUE_APP_CLOUDBASEURL}/cloud/accounts/completed?page=${this.currentCompletedPage}&limit=${this.currentCompletedLimit}&search=${this.currentCompletedSearch}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.completedCloudAccountRequests = response.data.items
            this.currentCompletedCount = Math.ceil(response.data.totalCount / this.currentCompletedLimit)
            this.completedCloudAccountRequests.forEach(x => {
              this.getUserDetails(x.approvalDetails.completedBy).then((y) => {
                x.completedBy = y[0].Value
              })
            })
            this.loadingCompletedCloudAccounts = false
          },
          (error) => {
            this.loadingCompletedCloudAccounts = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getCompletedCloudAccounts() })
          })
    },
    getEnvironments: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/environments?internetAccessible=true`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.environments = response.data
            return this.environments
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getEnvironments() })
          })
    }
  },
  mounted: function () {
    this.getEnvironments().then(() => {
      this.getPendingCloudAccounts()
      this.getCompletedCloudAccounts()
    })
  }
}
</script>

<style scoped>
.v-text-field{
  width: 500px;
}
</style>
