<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <span class="text-h5"><strong>Cloud Organizations</strong></span>
      </v-col>
    </v-row>
    <v-row justify="space-between">
      <v-col cols="4">
        <v-btn color="primary" large @click="addNewOrganization">Create New Organization</v-btn>
      </v-col>
      <v-col cols="4">
        <v-text-field
          solo
          clearable
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          disable-sort
          :items-per-page="limit"
          :options.sync="options"
          :server-items-length="count"
          :headers="headers"
          :items="organizations"
          :loading="isLoading"
          class="elevation-1"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn icon @click="editOrganization(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon :disabled="modifyingItems.includes(item.id)" :loading="modifyingItems.includes(item.id)" @click="deleteOrganization(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="displayDialog" width="1500">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card>
          <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>Add/Update Organization</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col>
                      <v-text-field
                        :rules="requiredField"
                        v-model="currentOrganization.displayName"
                        label="Display Name" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        :rules="requiredField"
                        v-model="currentOrganization.orgAbbreviation"
                        label="Abbreviation" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="displayDialog = false">Cancel</v-btn>
            <v-btn text color="primary" :loading="isSaving" :disabled="isSaving" @click="updateOrganization">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'cloud-organization',
  watch: {
    options: {
      handler () {
        this.page = this.options.page
        this.getOrganizations()
      },
      deep: true
    },
    search: function () {
      clearTimeout(this.searchTimeout)

      // Make a new timeout set to go off in 800ms
      this.searchTimeout = setTimeout(() => {
        if (this.search == null) {
          this.search = ''
        }
        this.page = 1
        this.getOrganizations()
      }, 500)
    }
  },
  data: () => ({
    searchTimeout: null,
    modifyingItems: [ ],
    valid: true,
    cloudUserPermissions: [
      {
        text: 'Read',
        value: 0
      },
      {
        text: 'Read/Write',
        value: 1
      },
      {
        text: 'Admin',
        value: 2
      }
    ],
    displayDialog: false,
    isLoading: false,
    isSaving: false,
    isDeleting: false,
    currentOrganization: {},
    count: 0,
    search: '',
    organizations: [],
    options: {},
    page: 1,
    limit: 15,
    headers: [
      {
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'displayName'
      },
      {
        text: 'Abbreviation',
        align: 'left',
        sortable: true,
        value: 'orgAbbreviation'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: false,
        value: 'actions'
      }
    ],
    requiredField: [
      v => !!v || 'Please Provide a value'
    ]
  }),
  methods: {
    addNewOrganization: function () {
      this.displayDialog = true
    },
    validate: function () {
      return this.$refs.form.validate()
    },
    updateOrganization: function () {
      this.isSaving = true
      if (this.currentOrganization.id) {
        // TODO: remove
        axios(
          `${process.env.VUE_APP_CLOUDBASEURL}/cloud/organizations/${this.currentOrganization.id}`,
          {
            method: 'PUT',
            data: JSON.stringify(this.currentOrganization),
            headers: { 'Content-Type': 'application/json' }
          })
          .then(
            (response) => {
              this.isSaving = false
              this.getOrganizations()
              this.displayDialog = false
            },
            (error) => {
              this.isSaving = false
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.updateOrganization() })
              return error
            })
      } else {
        axios(
          `${process.env.VUE_APP_CLOUDBASEURL}/cloud/organizations`,
          {
            method: 'POST',
            data: JSON.stringify(this.currentOrganization),
            headers: { 'Content-Type': 'application/json' }
          })
          .then(
            (response) => {
              this.isSaving = false
              this.getOrganizations()
              this.displayDialog = false
            },
            (error) => {
              this.isSaving = false
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.updateOrganization() })
              return error
            })
      }
    },
    deleteOrganization: function (item) {
      this.isDeleting = true
      axios(
        `${process.env.VUE_APP_CLOUDBASEURL}/cloud/organizations/${item.id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.modifyingItems.push(item.id)
            this.getOrganizations()
          },
          (error) => {
            this.modifyingItems = this.modifyingItems.filter(x => {
              return x !== item.id
            })
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteOrganization() })
            return error
          })
    },
    editOrganization: function (item) {
      this.adminTeam = [ ]
      this.currentOrganization = JSON.parse(JSON.stringify(item))
      this.displayDialog = true
    },
    getOrganizations: function () {
      this.isLoading = true
      return axios(
        `${process.env.VUE_APP_CLOUDBASEURL}/cloud/organizations?page=${this.page}&limit=${this.limit}&search=${this.search}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.organizations = response.data.items
            this.count = Math.ceil(response.data.totalCount / this.limit)
            this.isLoading = false
          },
          (error) => {
            this.isLoading = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getOrganizations() })
          })
    },
    deleteUser: function (index) {
      this.adminTeam.splice(index, 1)
    },
    getUserDetails: function (username) {
      return axios(
        `${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/user/${username}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getUserDetails() })
          })
    }
  },
  mounted: function () {
  }
}
</script>

<style scoped>

</style>
