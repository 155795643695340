<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <span class="text-h5">Cloud Line of Business:</span>
      </v-col>
    </v-row>
    <v-row justify="space-between">
      <v-col cols="4">
        <v-btn large color="primary" @click="addNewLob(); resetForm();">Create New LOB</v-btn>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          solo
          clearable
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          disable-sort
          :items-per-page="limit"
          :options.sync="options"
          :server-items-length="count"
          :headers="headers"
          :items="lobs"
          :loading="isLoading"
          class="elevation-1"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn icon @click="editLob(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon :disabled="isDeleting" :loading="isDeleting" @click="deleteLob(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="displayDialog" width="1500">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        id="formId"
      >
        <v-card>
          <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>Add/Update Line of Business</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col>
                      <v-text-field
                        :rules="requiredField"
                        v-model="currentLob.displayName"
                        label="Name" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        :rules="requiredField"
                        v-model="currentLob.abbreviation"
                        label="Abbreviation" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        :rules="requiredField"
                        v-model="currentLob.gcpFolderId"
                        label="GCP Folder id" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        :rules="requiredField"
                        v-model="currentLob.azureManagementGroupId"
                        label="Azure Management Group" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select :items="cloudOrganizations"
                      :isLoading="isLoadingCloudOrganizations"
                      item-value="id" item-text="displayName"
                      :rules="requiredField"
                      v-model="currentLob.organizationId"
                      label="Cloud Organization Group" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <user-group-search-component class="required" id="v-step-5" v-model="currentUserGroupSearch" label="User and Group Search" />
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">34</th>
                          <th class="text-left">Name</th>
                          <th class="text-left">Permission</th>
                          <th class="text-left">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(user, index) in adminTeam" :key="user.key">
                          <td>{{ user.key }}</td>
                          <td>{{ user.value }}</td>
                          <td>
                            <v-select :items="cloudUserPermissions" v-model="user.permission" label="Permission" />
                          </td>
                          <v-btn icon @click="deleteUser(index)"><v-icon>mdi-delete</v-icon></v-btn>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="primary" :loading="isSaving" :disabled="isSaving" @click="updateLob">Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn text @click="resetForm(); displayDialog=false">Cancel</v-btn>
            <v-btn color="primary" text @click="resetForm(); displayDialog=false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import userGroupSearchComponent from '@/components/userGroupSearchComponent'

export default {
  name: 'cloud-lob',
  components: {
    'user-group-search-component': userGroupSearchComponent
  },
  watch: {
    options: {
      handler () {
        this.page = this.options.page
        this.getLinesofBusiness()
      },
      deep: true
    },
    search: function () {
      clearTimeout(this.searchTimeout)

      // Make a new timeout set to go off in 800ms
      this.searchTimeout = setTimeout(() => {
        if (this.search == null) {
          this.search = ''
        }
        this.page = 1
        this.getLinesofBusiness()
      }, 500)
    },
    currentUserGroupSearch: function (newValue, oldValue) {
      let result = {}
      if (newValue) {
        if (newValue.Key) {
          result = {
            key: newValue.Key,
            value: newValue.Value,
            isGroup: false,
            permission: 0
          }
        } else {
          result = {
            key: newValue,
            value: newValue,
            isGroup: true,
            permission: 0
          }
        }

        if (!this.adminTeam.find(x => x === result.key)) {
          if (result.key.toLowerCase().startsWith('domain')) {
            this.$store.commit('showErrorDialog',
              {
                errorData: {
                  response: {
                    data: {
                      errorMessage: 'You cannot use a Domain Group for this list. Please select a more granular group'
                    }
                  }
                },
                persistErrorDialog: false
              })
          } else {
            this.adminTeam.push(result)
          }
        }
      }
    }
  },
  data: () => ({
    valid: true,
    currentUserGroupSearch: null,
    cloudUserPermissions: [
      {
        text: 'Read',
        value: 0
      },
      {
        text: 'Read/Write',
        value: 1
      },
      {
        text: 'Admin',
        value: 2
      }
    ],
    adminTeam: [],
    cloudOrganizations: [],
    isLoadingCloudOrganizations: false,
    displayDialog: false,
    isLoading: false,
    isSaving: false,
    isDeleting: false,
    currentLob: {},
    createdLob: {},
    count: 0,
    search: '',
    lobs: [],
    options: {},
    page: 1,
    limit: 15,
    headers: [
      {
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'displayName'
      },
      {
        text: 'abbreviation',
        align: 'left',
        sortable: true,
        value: 'abbreviation'
      },
      {
        text: 'GCP Folder Id',
        align: 'left',
        sortable: true,
        value: 'gcpFolderId'
      },
      {
        text: 'Azure Management Group',
        align: 'left',
        sortable: true,
        value: 'azureManagementGroupId'
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: false,
        value: 'actions'
      }
    ],
    requiredField: [
      v => !!v || 'Please Provide a value'
    ]
  }),
  methods: {
    addNewLob: function () {
      this.displayDialog = true
    },
    resetForm: async function () {
      // if statement is used to prevent vue render warning
      if (document.getElementById('formId')) {
        this.$refs.form.reset()
        this.adminTeam = []
      }
    },
    getCloudOrganizations: function () {
      this.isLoadingCloudOrganizations = true
      axios(
        `${process.env.VUE_APP_CLOUDBASEURL}/cloud/organizations?page=0&limit=100`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isLoadingCloudOrganizations = false
            this.cloudOrganizations = response.data.items
          },
          (error) => {
            this.isLoadingCloudOrganizations = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getCloudOrganizations() })
            return error
          })
    },
    validate: function () {
      return this.$refs.form.validate()
    },
    updateLob: async function () {
      this.isSaving = true
      this.createdLob = this.currentLob
      let team = {
        users: [ ]
      }
      this.adminTeam.forEach(x => {
        team.users.push({
          name: x.key,
          isGroup: x.isGroup,
          permission: x.permission
        })
      })

      this.currentLob.adminTeam = team
      // this should only occur if the user is pressing the edit button
      if (this.currentLob.id) {
        axios(
          `${process.env.VUE_APP_CLOUDBASEURL}/cloud/lob/${this.currentLob.id}`,
          {
            method: 'PUT',
            data: JSON.stringify(this.currentLob),
            headers: { 'Content-Type': 'application/json' }
          })
          .then(
            (response) => {
              this.isSaving = false
              this.getLinesofBusiness()
              this.displayDialog = false
            },
            (error) => {
              this.isSaving = false
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.updateLob() })
              return error
            })
      } else {
        axios(
          `${process.env.VUE_APP_CLOUDBASEURL}/cloud/lob`,
          {
            method: 'POST',
            data: JSON.stringify(this.currentLob),
            headers: { 'Content-Type': 'application/json' }
          })
          .then(
            (response) => {
              this.isSaving = false
              this.getLinesofBusiness()
              this.displayDialog = false
            },
            (error) => {
              this.isSaving = false
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.updateLob() })
              return error
            })
      }
      this.isSaving = false
      this.displayDialog = false
    },
    deleteLob: function (item) {
      this.isDeleting = true
      axios(
        `${process.env.VUE_APP_CLOUDBASEURL}/cloud/lob/${item.id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isDeleting = false
            this.getLinesofBusiness()
          },
          (error) => {
            this.isDeleting = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteLob() })
            return error
          })
    },
    editLob: function (item) {
      this.adminTeam = [ ]
      this.currentLob = JSON.parse(JSON.stringify(item))
      item.adminTeam.users.forEach(x => {
        if (!x.isGroup) {
          this.getUserDetails(x.name).then((user) => {
            this.adminTeam.push({
              key: user.Username,
              value: user.DisplayName,
              isGroup: x.isGroup,
              permission: x.permission
            })
          })
        } else {
          this.adminTeam.push({
            key: x.name,
            value: x.name,
            isGroup: x.isGroup,
            permission: x.permission
          })
        }
      })

      this.displayDialog = true
    },
    getLinesofBusiness: function () {
      this.isLoading = true
      return axios(
        `${process.env.VUE_APP_CLOUDBASEURL}/cloud/lob?page=${this.page}&limit=${this.limit}&search=${this.search}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.lobs = response.data.items
            this.count = Math.ceil(response.data.totalCount / this.limit)
            this.isLoading = false
          },
          (error) => {
            this.isLoading = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getCompletedDeployments() })
          })
    },
    deleteUser: function (index) {
      this.adminTeam.splice(index, 1)
    },
    getUserDetails: function (username) {
      return axios(
        `${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/user/${username}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getUserDetails() })
          })
    }
  },
  mounted: function () {
    this.getCloudOrganizations()
  }
}
</script>

<style scoped>

</style>
